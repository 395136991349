import React, { useEffect, useRef, useState, useContext  } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_SaleReport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [RoomType, setRoomType] = useState([]);
    const [cmbAccdata, setcmbAccdata] = useState([]);
    const [cmbAccdataBank, setcmbAccdataBank] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0,
        pay1acc: 0,
    pay2acc: 0,
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status,pay1acc,pay2acc } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async () => {
        setIsLoading(true);
        try {
            console.log(`${hostlink}/${usershopid}/billviewPartyFilter/${status}/${fromdate}/${todate}/${pay1acc}/${pay2acc}`)
            const billResponse = await axios.get(`${hostlink}/${usershopid}/billviewPartyFilter/${status}/${fromdate}/${todate}/${pay1acc}/${pay2acc}`);
            const billData = billResponse.data;
            setData(billResponse.data)

            const accResponse = await axios.get(`${hostlink}/${usershopid}/accmas`);
            const accmasData = accResponse.data;

            // Create a map for accmas by id
            const accmasMap = accmasData.reduce((acc, curr) => {
                acc[curr.id] = curr.accname;
                return acc;
            }, {});

            // Initialize containers for the sums
            const pay1Sums = {};
            const pay2Sums = {};

            // Calculate sums for pay1amt and pay2amt
            billData.forEach((item) => {
                const { SaleMasDTO } = item;

                // Pay1 calculation
                if (SaleMasDTO.pay1amt > 0 && accmasMap[SaleMasDTO.pay1code]) {
                    const accname = accmasMap[SaleMasDTO.pay1code];
                    pay1Sums[accname] = (pay1Sums[accname] || 0) + SaleMasDTO.pay1amt;
                }

                // Pay2 calculation
                if (SaleMasDTO.pay2amt > 0 && accmasMap[SaleMasDTO.pay2code]) {
                    const accname = accmasMap[SaleMasDTO.pay2code];
                    pay2Sums[accname] = (pay2Sums[accname] || 0) + SaleMasDTO.pay2amt;
                }
            });

            // Combine pay1Sums and pay2Sums into a single container
            const combinedSums = { ...pay1Sums };
            Object.keys(pay2Sums).forEach((key) => {
                combinedSums[key] = (combinedSums[key] || 0) + pay2Sums[key];
            });

            setTotalSums(combinedSums);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Data Not Found...");
        }
    };


    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Bill Report.xlsx');
    };
    useEffect(()=>{
            axios.get(`${hostlink}/${usershopid}/accmas`).then((Response) => { if (Response.data.length > 0) setcmbAccdata(Response.data); }).catch((Response) => { "data Not Found" })
            axios.get(`${hostlink}/${usershopid}/accmas`).then((Response) => { setcmbAccdataBank(Response.data); }).catch((Response) => { "data Not Found" })
    },[])

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode'> Sale Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Status:</label>
                    </div>
                    <div className='col-md-2'>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e) }} name='status' value={status} className="form-select">
                            {/* <option value={3}>All</option> */}
                            <option value={1}>Trash</option>
                            <option value={2}>Cancelled</option>
                            <option value={0}>Billed</option>

                        </select>
                    </div>
                    
                    <div className='col-md-3 align-self-center text-center'>
                        
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Pay 1:</label>
                    </div>
                    <div className='col-md-2'>
                    <select id="pay1acc" onChange={(e) => { onChangevalue(e) }}  value={pay1acc} name='pay1acc' class="form-select" >
                    <option selected key={0} value={0}>All</option>
                  {cmbAccdata.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.accname} </option>) })}
                </select>
                    </div>

                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Pay 2:</label>
                    </div>
                    <div className='col-md-2'>
                    <select id="pay2acc" onChange={(e) => { onChangevalue(e) }} value={pay2acc} name='pay2acc' class="form-select" >
                  <option selected key={0} value={0}>All</option>
                  {cmbAccdataBank.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.accname} </option>) })}
                </select>
                    </div>

                    <div className='col-md-3 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Bill Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{  overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th scope="col">Bl Date</th>
                            <th scope="col">Bl No</th>
                            <th scope="col">Time</th>
                            {/* <th scope="col">Type</th> */}
                            {/* <th scope="col">Table</th> */}
                            <th scope="col">Mobile No</th>
                            <th scope="col">Cust Name</th>
                            {/* <th scope="col">Item</th> */}
                            <th scope="col">Bill Amount</th>
                            {/* <th scope="col">Remarks</th> */}
                            <th scope="col">MOP-1 </th>
                            <th scope="col">MOP-1 Amt</th>
                            <th scope="col">MOP-2 </th>
                            <th scope="col">MOP-2 Amt</th>
                            {/* <th scope="col">Tip Amount</th> */}
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x} className={res.SaleMasDTO.status === 0 ? 'table-light' : res.SaleMasDTO.status === 1 ? 'table-danger' : res.SaleMasDTO.status === 2 ? 'table-success' : res.SaleMasDTO.status === 3 ? 'table-light' : ''}>
                                <td>{x + 1}</td>
                                <td>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                <td>{res.SaleMasDTO.shopvno}</td>
                                <td>{res.SaleMasDTO.entrytime}</td>
                                {/* <td>{res.bltype}</td> */}
                                {/* <td>{res.tablename}</td> */}
                                <td>{res.SaleMasDTO.custmob}</td>
                                <td>{res.SaleMasDTO.custname}</td>
                                {/* <td>{res.SaleMasDTO.itemview}</td> */}
                                <td>{res.SaleMasDTO.totblamt}</td>
                                {/* <td>{res.SaleMasDTO.billremarks}</td> */}
                                <td>{res.Pay1Name}</td>
                                <td>{res.SaleMasDTO.pay1amt}</td>
                                <td>{res.Pay2Name}</td>
                                <td>{res.SaleMasDTO.pay2amt}</td>
                                {/* <td>{res.SaleMasDTO.tipamount}</td> */}
                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            <td></td>
                            {/* <td></td> */}
                            <td></td>
                            <td></td>
                            <td><h6>Total :</h6></td>
                            <td><h6>{data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0)}</h6></td>
                            <td></td>
                            {/* <td></td> */}
                            <td><h6>{data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.pay1amt), 0)}</h6></td>
                            <td></td>
                            <td><h6>{data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.pay2amt), 0)}</h6></td>
                            {/* <td><h6>{data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.tipamount), 0)}</h6></td> */}
                        </tr>
                    </tbody>
                    
                </table>
                
                <table className="table" style={{ textAlign: "end", width: "400px" }}>
                    <thead>
                        
                        <tr>
                            <th>Account Name</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(totalSums).map((accname) => (
                            <tr key={accname}>
                                <td style={{ textAlign: "end", width: "200px" }}>{accname}</td>
                                <td style={{ textAlign: "end", width: "200px" }}>{totalSums[accname].toFixed(2)}</td> {/* Display total sum */}
                            </tr>
                        ))}
                        {/* <tr>
                            <th style={{color:"blue"}}>Tip Amount Total</th>
                            <th style={{color:"blue"}}>{data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.tipamount), 0).toFixed(2)}</th>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Bill Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Sr No</Text>
                            <Text style={styles.headerCell}>Bl Date</Text>
                            <Text style={styles.headerCell}>Bl No</Text>
                            <Text style={styles.headerCell}>Time</Text>
                            <Text style={styles.headerCell}>Type</Text>
                            <Text style={styles.headerCell}>Table</Text>
                            <Text style={styles.headerCell}>Mobile No</Text>
                            <Text style={styles.headerCell}>Cust Name</Text>
                            <Text style={styles.headerCell}>Item</Text>
                            <Text style={styles.headerCell}>Bill Amt</Text>
                        </View>
                        {data.map((res, x) => (
                            <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{x + 1}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.shopvno}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.entrytime ? new Date(`2022-01-01T${res.SaleMasDTO.entrytime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</Text>
                                <Text style={styles.cell}>{res.bltype}</Text>
                                <Text style={styles.cell}>{res.tablename}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.custmob}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.custname}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.itemview}</Text>
                                <Text style={styles.cell}>{res.SaleMasDTO.totblamt}</Text>
                            </View>
                        ))}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{totalBillAmount}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_SaleReport
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'

import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import Loading from './Loading'
import CryptoJS from 'crypto-js';
import softwaresecretKey from '../Hostlink/softwaresecretKey'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const UserPermission = () => {
    const [isLoading, setIsLoading] = useState(false);
    
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;

    


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
// "Item Company Master": "itemcompanymaster",
    // Define the page to column name mapping
    const pageMapping = {
        "Item Master": "itemmaster",
        "Item Unit Master": "itemunitmaster",
        "Item Group Master": "itemgroupmaster",
        "Dish Type Master": "dishtypemaster",
        "Dish Head Master": "dishheadmaster",
        "Floor Master": "floormaster",
        "Room Type": "roomtype",
        "Room Master": "roommaster",
        "Food Package": "foodpackage",
        "Table Master": "tablemaster",
        "Waiter Master": "waitermaster",
        "Banquet Hall": "banquethall",
        "Comment Master": "commentmaster",
        "Department Master": "departmentmaster",
        "Account Master": "accountmaster",
        "Kitchen Master": "kitchenmaster",
        "Reservation": "reservation",
        "Check in": "checkin",
        "Service": "service",
        "Place Order": "placeorder",
        "Check out": "checkout",
        "Kot View": "kotview",
        "Bill View": "billview",
        "Transfer Bill": "transferbill",
        "Hotel Bill View": "hotelbillview",
        "Logbook": "logbook",
        "Room Status": "roomstatus",
        "Hotel Reservation Report": "hotelreservationreport",
        "Banquet Reservation Report": "banquetreservationreport",
        "Check-In/Out Report": "checkinoutreport",
        "Hotel Bill Report": "hotelbillreport",
        "Hotel-Trash Bill": "hoteltrashbill",
        "Hotel-Cancelled Bill": "hotelcancelledbill",
        "Cancelled Item (KOT)": "cancelleditemkot",
        "Table Status": "tablestatus",
        "Sale Report Bill-wise": "salereportbillwise",
        "Sale Report Item-wise": "salereportitemwise",
        "Sale Report Item-Summary": "salereportitemsummary",
        "Sale-Trash Bill": "saletrashbill",
        "Sale-Cancelled Bill": "salecancelledbill",
        "Stock Report": "stockreport",
        "Purchase Entry": "purchaseentry",
        "Purchase Report": "purchasereport",
        "Transaction": "transaction",
        "Ledger View": "ledgerview",
        "Wastage Entry": "wastageentry",
        "Wastage Report": "wastagereport",
        "Stock Transfer": "stocktransfer",
        "Stock Transfer Report": "stocktransferreport",
        "Purchase order (PO)": "purchaseorderpo",
        "Production Entry": "productionentry",
        "Costing report": "costingreport",
        "Bill of Materials (BOM)": "billofmaterialsbom",
        "Book Banquet Hall": "bookbanquethall",
        "Configuration": "configuration",
        "Store Item Display": "storeitemdisplay",
        "Store Dish-head Display": "storedishheaddisplay",
        "Store Dish-Type Display": "storedishtypedisplay",
        "NA": "itemcompanymaster",
        "NA1": "hdcustomer",
    };

    // Dynamically generate the initial permissions state based on the pageMapping
    const initialPermissions = Object.keys(pageMapping).reduce((acc, page) => {
        const columnName = pageMapping[page];
        acc[columnName] = { view: false, create: false, edit: false, delete: false };
        return acc;
    }, {});

    // Initial permissions state
    const [permissions, setPermissions] = useState(initialPermissions);

    // Master state for check/uncheck all actions
    const [masterPermission, setMasterPermission] = useState({
        view: false,
        create: false,
        edit: false,
        delete: false
    });

    // Handle checkbox change for individual permissions
    const handleCheckboxChange = (columnName, permission) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [columnName]: {
                ...prevPermissions[columnName],
                [permission]: !prevPermissions[columnName][permission]
            }
        }));
    };
    const handleMasterCheckboxChange = (permission) => {
        const newMasterPermission = !masterPermission[permission];
        setMasterPermission({
            ...masterPermission,
            [permission]: newMasterPermission
        });
    
        // Define fields to skip
        const skipFields = ["id", "username", "password", "shopid", "narration", "shopname"];
    
        // Update all page permissions for the selected permission (view, create, edit, delete)
        setPermissions((prevPermissions) => {
            const updatedPermissions = { ...prevPermissions };
            
            Object.keys(updatedPermissions).forEach((columnName) => {
                // Skip fields that should not be affected
                if (skipFields.includes(columnName)) {
                    return;
                }
                // Ensure that permissions[columnName] is always an object
                if (typeof updatedPermissions[columnName] !== 'object') {
                    updatedPermissions[columnName] = { view: false, create: false, edit: false, delete: false };
                }
                updatedPermissions[columnName][permission] = newMasterPermission;
            });
    
            return updatedPermissions;
        });
    };
    


    // Convert permissions to binary string and prepare for saving
    const handleSave = async () => {
        
        if (username === "") { toast.error("Please Enter User Name"); document.getElementById("inputname").focus(); return false }
        if (password === "") { toast.error("Please Enter Password"); document.getElementById("password").focus(); return false }

        const saveData = {};
        // Convert permissions for each page to binary string
        // for (const columnName in permissions) {
        //     const perms = permissions[columnName];
        //     saveData[columnName] = `${perms.view ? '1' : '0'}${perms.create ? '1' : '0'}${perms.edit ? '1' : '0'}${perms.delete ? '1' : '0'}`;
        // }

        // Define fields to skip in the permissions transformation
        const skipFields = ["id", "username", "password", "shopid", "narration", "shopname"];

        // Convert permissions for each page to a binary string, skipping specified fields
        for (const columnName in permissions) {
            if (skipFields.includes(columnName)) {
                continue; // Skip fields that should not be transformed
            }
            const perms = permissions[columnName];
            saveData[columnName] = `${perms.view ? '1' : '0'}${perms.create ? '1' : '0'}${perms.edit ? '1' : '0'}${perms.delete ? '1' : '0'}`;
        }


        var ld = { ...saveData, id: permissions.id, shopid: usershopid, shopname: appState[0].cname, username: username, password: password, narration: usershopid + "_" + username }
        
        // Save the data to your backend (send saveData to your API)
        // return false;
        document.getElementById("cmdsave").disabled = true
        try {
            if (document.getElementById("pagemode").innerHTML == ' User [ NEW ]') {
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: document.getElementById("inputname").value.trim(), operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/UserPermission")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("User Name already exists.");
                        document.getElementById("inputname").focus();
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                var ld = { ...saveData, id: permissions.id, shopid: usershopid, shopname: appState[0].cname, username: username, password: password, narration: usershopid + "_" + username }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = { ...logdetail, newdesc: "Name : " + document.getElementById("inputname").value.trim() }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/UserPermission")
                        }, 1000);
                    })
                    .catch(() => {

                        toast.error("Name already exists.");
                        document.getElementById("inputname").focus();
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }



    };

    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/UserPermissionMas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const Closeform = () => {
        window.location.assign("/UserPermission")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "UserPermission",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const [savedata, setSavedata] = useState({
        id: "",
        username: "",
        password: "",
        shopid: usershopid,
        narration: ""
    })
    const { shopid, password, username } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`%^*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setIsLoading(true);
        setPagemode(1);
        document.getElementById("cmdnew").disabled = true
        console.log(`${hostlink}${api}/${e}`)
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                document.getElementById("pagemode").innerHTML = " User [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                document.getElementById("inputname").focus();
                setSavedata(Response.data);
                setPermissions(Response.data);
                
                const permissionsData = Response.data;
                const formattedPermissions = {};


                const skipFields = ["id", "username", "password", "shopid", "narration"];

                // Loop through the response data and transform each page's permission
                Object.keys(permissionsData).forEach((key) => {
                    // Skip fields that we don't want to convert
                    if (skipFields.includes(key)) {
                        formattedPermissions[key] = permissionsData[key];
                    } else {
                        const permissionString = permissionsData[key]; // Example: "1000"
                        formattedPermissions[key] = {
                            view: permissionString[0] === '1',
                            create: permissionString[1] === '1',
                            edit: permissionString[2] === '1',
                            delete: permissionString[3] === '1'
                        };
                    }
                });

                setPermissions(formattedPermissions);
                
                var ld = { ...logdetail, olddesc: "Name : " + Response.data.username, operation: "Edit" }
                setlogdetail(ld);
                setIsLoading(false);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"

        document.getElementById("pagemode").innerHTML = " User [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, flname) => {
        try {
            await axios.delete(`${hostlink}${api}/${deleteid}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")

            setInterval(() => {
                handleCloseModal();
                window.location.assign("/UserPermission")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'SNo', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "16%" },
        { name: 'User Name', sortable: true, selector: row => row.username, },
        { name: 'User id', sortable: true, selector: row => row.narration, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/UserPermission"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp;
                <Link onClick={(e) => { handleOpenModal(row.id, row.username) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile">Delete</span></Link>&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br /> User : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>

            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdnew").disabled = false
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        
        axios.get(`${hostlink}/${usershopid}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [refreshData,usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-user-gear"></i >  <span id='pagemode' >  [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div className="row">
                    <div class="col-md-5">
                        <label className='font-monospace' class="form-label"> User Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='username' value={username} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-5">
                        <label className='font-monospace' class="form-label"> Password</label>
                        <input type="password" onChange={(e) => { onChangevalue(e) }} name='password' value={password} class="form-control" id="password" /> <br />
                    </div>
                    <div class="col-md-2 text-end">
                        <br />
                        {/* handleSave */}
                        {/* <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp; */}
                        <button onClick={(e) => (handleSave(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                        <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    </div>
                </div>
                <div className="row p-1">
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th>Page Name</th>
                                <th><input type="checkbox" checked={masterPermission.view} onChange={() => handleMasterCheckboxChange('view')} /> View </th>
                                <th> <input type="checkbox" checked={masterPermission.create} onChange={() => handleMasterCheckboxChange('create')} /> Create </th>
                                <th> <input type="checkbox" checked={masterPermission.edit} onChange={() => handleMasterCheckboxChange('edit')} /> Edit </th>
                                <th> <input type="checkbox" checked={masterPermission.delete} onChange={() => handleMasterCheckboxChange('delete')} /> Delete </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(pageMapping).map((displayName) => {
                                const columnName = pageMapping[displayName];  // Get column name from mapping
                                const perms = permissions[columnName];        // Get permissions for that page
                                return (
                                    <tr key={displayName} style={{ borderBottom: "1px solid black" }}>
                                        <td>{displayName}</td> {/* Display user-friendly page name */}
                                        <td> <input type="checkbox" checked={perms.view} onChange={() => handleCheckboxChange(columnName, 'view')} /> </td>
                                        <td> <input type="checkbox" checked={perms.create} onChange={() => handleCheckboxChange(columnName, 'create')} /> </td>
                                        <td> <input type="checkbox" checked={perms.edit} onChange={() => handleCheckboxChange(columnName, 'edit')} /> </td>
                                        <td> <input type="checkbox" checked={perms.delete} onChange={() => handleCheckboxChange(columnName, 'delete')} /> </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <td colSpan="5">
                                    <button onClick={handleSave}>Save Permissions</button>
                                </td> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>

                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-user-gear"></i > User Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default UserPermission
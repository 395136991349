import axios from 'axios'

import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const ItemMaster = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const prefix = appState?.[0]?.itemcodeformat || '';
    const format = appState?.[0]?.itemcodedigit || '0000';
    const api = "/item";
    const usershopiditemprintname = appState?.[0]?.otheritemprintname || 0;

    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [DishType, setDishType] = useState([])
    const [Unitmas, setUnitmas] = useState([])

    const [Itg, setItg] = useState([])
    const [Itc, setItc] = useState([])
    const [Kitchen, setKitchen] = useState([])
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [logdetail, setlogdetail] = useState({
        onform: "ItemMaster",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const Closeform = () => {
        window.location.assign("/ItemMaster")
    }

    const searchData = (e) => {

        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const FindnewBarcode = async (e) => {
        setIsLoading(true);  // Start loading indicator
    
        if (usershopid !== 0) {
            try {
                // Send the GET request to get the new barcode
                const response = await axios.get(`${hostlink}/findnewbarcode/${usershopid}/${prefix}/${format}`);
    
                console.log(response.data);  // Log the response data (new barcode)
    
                // Update savedata with the new barcode
                setSavedata({ ...savedata, barcode: response.data });
            } catch (error) {
                console.error("Error fetching new barcode:", error);
            } finally {
                // Hide the loading indicator after the request is complete (success or failure)
                setIsLoading(false);
            }
        }
    
        // Focus on the barcode input after completion
        document.getElementById("inputnamebarcode").focus();
    
        return false;  // Prevent the default form submission or event behavior
    };
    

    const SaveData = async (e) => {

        const inputValue = document.getElementById("inputnameitem").value.trim();
        if (inputValue === "") {
            document.getElementById("inputnameitem").focus();
            toast.error("Please Enter Name")
            return false
        }
        const inputValue2 = document.getElementById("inputnamebarcode").value.trim();
        if (inputValue2 === "") {
            document.getElementById("inputnamebarcode").focus();
            toast.error("Please Enter Barcode")
            return false
        }


        document.getElementById("cmdsave").disabled = true
        setIsLoading(true);
        try {

            if (pagemode == '0') {

                // Check item name
                const nameQuery = `select * from itemmas where itname = '${itname}' and shopid = ${usershopid} limit 1`;
                const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
                if (nameResponse.data.length > 0) {
                    toast.error("Item Name already exist..."); document.getElementById("cmdsave").disabled = false; setIsLoading(false); document.getElementById("inputnameitem").focus();
                    return false;
                }
                // Check barcode
                const barcodeQuery = `select * from itemmas where barcode = '${barcode}' and shopid = ${usershopid} limit 1`;
                const barcodeResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(barcodeQuery)}`);
                if (barcodeResponse.data.length > 0) {
                    toast.error("Barcode already exist..."); setIsLoading(false); document.getElementById("cmdsave").disabled = false; document.getElementById("inputnamebarcode").focus(); return false;
                }

                var ld = { ...savedata, shopid: usershopid, narration2: usershopid + document.getElementById("inputnamebarcode").value.trim(), narration: usershopid + document.getElementById("inputnameitem").value.trim() }

                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull");
                        var ld = { ...logdetail, newdesc: "Name : " + `${itname}` + ", barcode : " + `${barcode}` + ", hsncode : " + `${hsncode}` + ", Rest Rate : " + `${restrate}` + ", D.Rate : " + `${dlvrate}` + ", T.Rate : " + `${onlinerate}`, operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/ItemMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                const nameQuery = `select * from itemmas where itname = '${itname}' and id not in (${id}) and shopid = ${usershopid} limit 1`;
                const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
                if (nameResponse.data.length > 0) {
                    toast.error("Item Name already exist..."); document.getElementById("cmdsave").disabled = false; setIsLoading(false); document.getElementById("inputnameitem").focus();
                    return false;
                }
                // Check barcode
                const barcodeQuery = `select * from itemmas where barcode = '${barcode}'and id not in (${id})  and shopid = ${usershopid} limit 1`;
                const barcodeResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(barcodeQuery)}`);
                if (barcodeResponse.data.length > 0) {
                    toast.error("Barcode already exist..."); setIsLoading(false); document.getElementById("cmdsave").disabled = false; document.getElementById("inputnamebarcode").focus(); return false;
                }
                var ld = { ...savedata, narration2: usershopid + document.getElementById("inputnamebarcode").value.trim(), narration: usershopid + document.getElementById("inputnameitem").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        var ld = { ...logdetail, newdesc: "Name : " + `${itname}` + ", barcode : " + `${barcode}` + ", hsncode : " + `${hsncode}` + ", Rest Rate : " + `${restrate}` + ", D.Rate : " + `${dlvrate}` + ", T.Rate : " + `${onlinerate}` }
                        setlogdetail(ld);
                        saveLog(ld);
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/ItemMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        setIsLoading(false);
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        itname: "",
        itnameprint: "",
        barcode: "",
        hsncode: "",
        takerate: "",
        unitcode: 0,
        restrate: 0,
        isdiscountable: 0,
        dlvrate: "",
        onlinerate: "",
        purcrate: "",
        mrp: "",
        opstock: "",
        itg: "",
        itc: "",
        dtcode: "",
        kcode: "",
        gst: "",
        cess: "",
        shopid: usershopid,
        discperc: 0,
        narration: "",
        isfav: 0,
        ispackage: 0,
        narration2: ""
    })
    const { id, itname, itnameprint, barcode, hsncode, takerate, narration, narration2, restrate, dlvrate, onlinerate, purcrate, mrp, isdiscountable, ispackage, isfav, opstock, itg, itc, unitcode, dtcode, kcode, gst, cess, discperc } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setIsLoading(true);
        setPagemode(1);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                document.getElementById("pagemode").innerHTML = " Item [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true

                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                setIsLoading(false);
                var ld = { ...logdetail, olddesc: "Name : " + `${Response.data.itname}` + ", barcode : " + `${Response.data.barcode}` + ", hsncode : " + `${Response.data.hsncode}` + ", Rest Rate : " + `${Response.data.restrate}` + ", D.Rate : " + `${Response.data.dlvrate}` + ", T.Rate : " + `${Response.data.onlinerate}`, operation: "Edit" }
                setlogdetail(ld);
                setSavedata(Response.data);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdCopy = (e) => {
        setIsLoading(true);
        setPagemode(0);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                Response.data.id = '';
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "New" }
                setlogdetail(ld);
                document.getElementById("pagemode").innerHTML = " Item [ NEW ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("frm_controll_Add_Edit")
                document.getElementById("inputnameitem").focus();
                setSavedata(Response.data);
                setIsLoading(false);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {

        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"
        var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "New" }
        setlogdetail(ld);
        document.getElementById("pagemode").innerHTML = " Item [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputnameitem").focus();
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const deleteData = async (e) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                window.location.assign("/ItemMaster")
            }, 1000);
        } catch (error) { }
    }

    useEffect(() => {
        if (usershopid != 0) {
            axios.get(`${hostlink}/${usershopid}/unit`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setUnitmas(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })

            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
            axios.get(`${hostlink}/${usershopid}/dhmas`)

                .then((FirstdhcodeDATA) => {

                    setDishType(FirstdhcodeDATA.data)
                    const firstdhcode = FirstdhcodeDATA.data[0][0];
                    axios.get(`${hostlink}/${usershopid}/ItemGroup`).then((ResponseITGcode) => {
                        setItg(ResponseITGcode.data)
                        const firstitgcode = ResponseITGcode.data[0];
                        axios.get(`${hostlink}/${usershopid}/kitchen`).then((Firstkitchencode) => {
                            setKitchen(Firstkitchencode.data)
                            const firstkitchencode = Firstkitchencode.data[0];
                            setSavedata({
                                ...savedata,
                                itg: firstitgcode ? firstitgcode.id : null,
                                dtcode: firstdhcode ? firstdhcode.id : null,
                                kcode: firstkitchencode ? firstkitchencode.id : null
                            });
                        }).catch((Response) => { "data Not Found" })
                    }).catch((Response) => { "data Not Found" })
                })
                .catch((Response) => { "data Not Found" })
        }
    }, [usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-sitemap"></i> <span id='pagemode' > Item [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div className='row p-2 m-1'>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Item Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='itname' value={itname} class="form-control" id="inputnameitem" autoComplete='off' />
                    </div>



                    {usershopiditemprintname === 1 && (
                        <>
                            <div class="col-md-6">
                                <label className='font-monospace' class="form-label"> Item Print Name </label>
                                <input type="text" onChange={(e) => { onChangevalue(e) }} name='itnameprint' value={itnameprint} class="form-control" id="itnameprint" />
                            </div>
                        </>
                    )}


                    <div class="col-md-3">
                        <div className="row">
                            <div className="col-6">
                                <label className='font-monospace' class="form-label"> Barcode </label>
                            </div>
                            <div className="col-6 text-end">
                                <Link href="" onClick={(e)=>{FindnewBarcode(1)}}>Generate code</Link>

                            </div>
                        </div>

                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='barcode' value={barcode} class="form-control" id="inputnamebarcode" autoComplete='off' />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> HSN Code</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='hsncode' value={hsncode} class="form-control" id="inputname" />
                    </div>
                    <p></p>
                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Item Group Name </label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='itg' value={itg} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {Itg.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.groupname} </option>
                                )
                            })}
                        </select>
                    </div>

                    {/* <div class="col-md-3">
                        <label for="inputState" class="form-label">Item Company Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='itc' value={itc} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Itc.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.itcname} </option>
                                )
                            })}
                        </select>
                    </div> */}
                    {/* <div class="col-md-3">
                        <label for="inputState" class="form-label">Kitchen Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='kcode' value={kcode} class="form-select">
                            {Kitchen.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.kitchenname} </option>
                                )
                            })}
                        </select>
                    </div> */}

                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Sub Category Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='dtcode' value={dtcode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {DishType.map((x) => {
                                return (
                                    <option key={x[0].id} value={x[0].id}> {x[0].dhname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Unit Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='unitcode' value={unitcode} class="form-select">
                            <option selected key={0} value={0}>NA</option>
                            {Unitmas.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.unitname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <p className='m-1'></p><p></p>
                    <hr />
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Sale Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='restrate' value={restrate} class="form-control" id="inputname" />
                    </div>
                    {/* <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Takeaway Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='takerate' value={takerate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Delivery Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='dlvrate' value={dlvrate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Online Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='onlinerate' value={onlinerate} class="form-control" id="inputname" />
                    </div> */}
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Mrp </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mrp' value={mrp} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Purchase Rate </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='purcrate' value={purcrate} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Gst % </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='gst' value={gst} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Cess % </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cess' value={cess} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Discount % </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discperc' value={discperc} class="form-control" id="inputname" />
                    </div>

                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Opening Stock </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='opstock' value={opstock} class="form-control" id="inputname" />
                    </div>

                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label"> Is favourite </label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='isfav' value={isfav} class="form-select">
                            <option selected key={0} value={0}>No</option>
                            <option selected key={1} value={1}>Yes</option>
                        </select>
                    </div>

                    {/* <div class="col-md-2">
                        <label className='font-monospace' class="form-label">In Package </label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='ispackage' value={ispackage} class="form-select">
                            <option selected key={0} value={0}>No</option>
                            <option selected key={1} value={1}>Yes</option>
                        </select>
                    </div> */}
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Is Discountable </label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='isdiscountable' value={isdiscountable} class="form-select">
                            <option selected key={1} value={1}>No</option>
                            <option selected key={0} value={0}>Yes</option>
                        </select>
                    </div>

                </div>
                <div class="col-md-12 text-end">
                    <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-sitemap"></i> Item Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'><i class="fa-solid fa-plus"></i> &nbsp; Create New</button>) : null}
                    </div>
                    <p></p>
                    <div style={{ maxHeight: "580px", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped" style={{}}>
                            <thead>
                                <tr>
                                    <th scope="col" className="hide-on-mobile">SNo</th>
                                    <th scope="col" className="f-size-on-mobile"><span className="hide-on-mobile" >Name</span> <span className="detail-on-mobile">Item Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Barcode</th>
                                    <th scope="col" className="hide-on-mobile">HSN Code</th>
                                    <th scope="col" className="hide-on-mobile">Rate</th>
                                    <th scope="col" className="hide-on-mobile">Discount</th>
                                    <th scope="col" className="f-size-on-mobile">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((res, x) => (
                                    <tr><th scope="row" className="hide-on-mobile">{x + 1}</th>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <b> <span className='show-on-mobile'>Item Name : </span>  </b>{res.itname} <br />
                                            <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start" }}>
                                                {res.barcode !== 0 && (<>Barcode : {res.barcode} <br /></>)} {res.hsncode !== '' && (<>HSN code : {res.hsncode} <br /></>)} {res.restrate !== '' && (<>Rate : {res.restrate} <br /></>)} {res.discperc !== 0 && (<>Discount : {res.discperc} % <br /></>)} <span style={{ color: "blue" }}> </span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.barcode}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.hsncode}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.restrate}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.discperc}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(2) === '1') ? (<Link to={"/ItemMaster"} onClick={(e) => { cmdEdit(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null}&nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(2) === '1') ? (<Link to={"/ItemMaster"} onClick={(e) => { cmdCopy(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-regular fa-clone"></i><span className="hide-on-mobile"> Copy</span></Link>) : null} &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(res.id, res.itname) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null}&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Item : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}
export default ItemMaster

import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css';
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const AccountMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/accmas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/AccountMaster")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "AccountMaster",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {
        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false }
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        try {
            if (document.getElementById("pagemode").innerHTML == ' Floor [ NEW ]') {

                var ld = { ...savedata,shopid: usershopid, narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: document.getElementById("inputname").value.trim(), operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/AccountMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        console.log(`${hostlink}${api}`)
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                var ld = { ...savedata,  shopid: usershopid,narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = { ...logdetail, newdesc: "Name : " + document.getElementById("inputname").value.trim() }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/AccountMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        console.log(`${hostlink}${api}`)
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        accname: "",
        add1: "",
        add2: "",
        pcode: "",
        gstno: "",
        mobno: "",
        email: "",
        acctypeview:-1,
        shopid: usershopid,
        acctype:0,
        narration: ""
    })
    const { shopid, accname, add1, add2, pcode, gstno, mobno, email,acctype,acctypeview } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        setIsLoading(true);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                document.getElementById("pagemode").innerHTML = " Account [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"        
                setIsLoading(false);
                var ld = { ...logdetail, olddesc: "Name : " + Response.data.accname, operation: "Edit" }
                setlogdetail(ld);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
        document.getElementById("pagemode").innerHTML = " Account [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, flname) => {
        try {
            await axios.delete(`${hostlink}${api}/${deleteid}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")

            setInterval(() => {
                handleCloseModal();
                window.location.assign("/AccountMaster")
            }, 1000);
        } catch (error) { }
    }

    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        if(usershopid!=0){
            axios.get(`${hostlink}/${usershopid}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        }
        
    }, [refreshData,usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-tree"></i> <span id='pagemode' > Account [ New ]</span></h5>
                <div className="row">
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Party Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='accname' value={accname} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Address 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Address 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Pin Code</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='pcode' value={pcode} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Mobile No</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mobno' value={mobno} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Email</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname" /> <br />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Account Type </label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='acctype' value={acctype} class="form-select">
                            <option selected key={0} value={0}>Cash</option>
                            <option selected key={1} value={1}>Bank</option>
                            <option selected key={2} value={2}>Customer</option>
                            <option selected key={3} value={3}>Supplier</option>
                            <option selected key={4} value={4}>Direct exp</option>
                            <option selected key={5} value={5}>In-Direct exp</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-regular fa-address-card" style={{ width: "20px" }}></i> Account Master</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>

                    <div className='col-md-2 rounded text-end' >
                    {/* <label className='font-monospace' class="form-label"> Account Type </label> */}
                    </div>
                    <div className='col-md-2 rounded' >
                        {/* <select id="acctypeview" onChange={(e) => { onChangevalue(e) }} name='acctypeview' value={acctypeview} class="form-select">
                            <option selected key={-1} value={-1}>All</option>
                            <option value={0}>Cash</option>
                            <option value={1}>Bank</option>
                            <option value={2}>Customer</option>
                            <option value={3}>Supplier</option>
                            <option value={4}>Direct exp</option>
                            <option value={5}>In-Direct exp</option>
                        </select> */}
                    </div>

                    <div className='col-md-2 text-end'>
                    {(userType === 0 || appState[1]?.[0]?.accountmaster?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>) : null}
                    </div>
                    <div style={{ maxHeight: "100vh", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="f-size-on-mobile"><span className="hide-on-mobile" >Party Name</span> <span className="detail-on-mobile">Account Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Address</th>
                                    <th scope="col" className="hide-on-mobile">Mobile</th>
                                    <th scope="col" className="hide-on-mobile">Email</th>
                                    <th scope="col" className="hide-on-mobile">GST No</th>
                                    <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}><th scope="row" className="f-size-on-mobile " style={{ verticalAlign: 'middle', height: '100%' }}>
                                        <span className='show-on-mobile'>Party Name : </span> {res.accname} <br />
                                        <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start" }}>
                                            {res.mobno !== 0 && (<>Mob : {res.mobno} <br /></>)} {res.add1 !== '' && (<>Address : {res.add1} <br /></>)} {res.email !== '' && (<>Email : {res.email} <br /></>)} <span style={{ color: "blue" }}> </span>
                                        </div>
                                    </th>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.add1}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.mobno}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.email}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.gstno}</td>
                                        <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                        {(userType === 0 || appState[1]?.[0]?.accountmaster?.charAt(2) === '1') ? (<Link to={"/AccountMaster"} onClick={(e) => { cmdEdit(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null} &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                            {(userType === 0 || appState[1]?.[0]?.accountmaster?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(res.id, res.accname) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile">Delete</span></Link>) : null}&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Account : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default AccountMaster
